import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import BlogBink, {
  useBlogBink,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Images
import { motion } from 'framer-motion'

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Team: React.FC<TeamProps> = ({ fields }) => {
  const {
    allWpTeamlid,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.teamQueryQuery>(graphql`
    query teamQuery {
      allWpTeamlid(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalTeamlidFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpTeamlid.edges

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const Content = styled(ParseContent)`

@media (min-width: 992px){
 & h2 {
  font-size: 40px;
  line-height: 40px;
 }
}
`
const Image = styled(Plaatjie)`
  height: 450px;

  @media (max-width: 767px){
    height: 300px;
  }
`

const Info = styled.div`
  bottom: 1.5rem;

  padding: 0 1rem;

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bold;
  }

  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: 16px;
    margin-bottom: 0;
  }
`

const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, rgba(24,38,63,0) 45%, rgba(24,38,63,1) 100%);
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()
  const [team, setTeam] = React.useState<BlogBinkPosts>([])

  useEffect(() => {
    const experts = blogBink.posts
      .sort(() => Math.random() - 0.5)
      .filter((value, index, self) => self.findIndex((e) => e.node.databaseId === value.node.databaseId) === index)

    setTeam(experts)
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 mb-4">
          <Content content={fields?.description} />
        </div>
        <div className="col-sm-6" />
        {blogBink.posts.length > 0 &&
          team.map((post) => {
            const { node }: any = post

            return (
              <div key={Math.random() + node.databaseId} className="col-6 col-lg-4 col-xl-3 mb-4">
                <div className="h-100">
                  <div className="position-relative h-100">
                    <motion.div className="h-100">
                      <div>
                        <Image image={node.featuredImage.node} alt="" />
                      </div>
                      <Gradient />
                      <Info className="position-absolute">
                        <h2>{node.recap.name}</h2>
                        <p>{node.recap.functie}</p>
                      </Info>
                    </motion.div>
                  </div>
                </div>
              </div>
            )
          })}
        {blogBink.posts.length < 1 && (
          <div className="col-lg-6 mt-4">
            <h2>Helaas!</h2>
            <p>
              We hebben geen berichten met deze categorie kunnen vinden. Probeer
              een andere categorie of probeer het later nog eens.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Team
