/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

import BlogBink, {
  useBlogBink,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Elements
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'
import ParseContent from 'components/shared/ParseContent'

// Images
import Chevron from 'img/chevron.inline.svg'
import Checked from 'img/checked.svg'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Projects: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProject,
    allWpProjectcategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectQueryQuery>(graphql`
    query projectQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
      allWpProjectcategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            databaseId
            name
            wpParent {
              node {
                databaseId
                name
              }
            }
            wpChildren {
              nodes {
                databaseId
                name
                wpParent {
                  node {
                    databaseId
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  const mainCategories = allWpProjectcategory.edges.filter(
    (category: any) => !category.node.wpParent
  )

  const subCategories = allWpProjectcategory.edges.filter(
    (category: any) => category.node.wpParent
  )

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={subCategories}
        categoryName="projectcategories"
        id="projects-overview"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-3">
              <div className="d-lg-block d-none">
                <BlogFilters mainCategories={mainCategories} />
              </div>
            </div>
            <div className="d-lg-none d-flex">
              <MobileBlogFilters mainCategories={mainCategories} />
            </div>
            <div className="col-lg-9">
              <BlogGrid fields={fields} />
            </div>
          </div>
        </div>
      </BlogBink>
    </section>
  )
}

const FilterWrapper = styled.div`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 24px;
  }
`

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  color: ${({ theme }) => theme.color.dark};

  ${(props) =>
    props.selected &&
    css`
      & input {
        background-image: url(${Checked});
        background-size: 13px 13px;
        background-repeat: no-repeat;
      }
    `}
`

const Input = styled.input<{ checked: boolean }>`
  position: relative;
  border: 1px solid #ffffff;
  background-color: ${({ theme }) => theme.color.light};
  appearance: none;
  width: 15px;
  height: 15px;
  box-shadow: 0px 0px 3px 0px #00000040;
`

const MobileFilterCollapsed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.primary};
  padding: 0.75rem 1rem;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  z-index: 4;
  width: 100%;

  & svg {
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    & path {
      fill: #fff;
    }
  }
`

const MobileFilterExpanded = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 4;
  padding: 1.5rem 1rem;
  overflow-y: scroll;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  color: ${({ theme }) => theme.color.light};
  & span {
    color: ${({ theme }) => theme.color.light};
  }
  & svg {
    transform: rotate(90deg);
    & path {
      fill: #fff;
    }
  }
`

interface BlogFiltersProps {
  mainCategories: any
}

interface BlogFilterProps {
  node: {
    id: string
    name: string
    wpParent: any
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = ({ mainCategories }: any) => {
  const blogBink = useBlogBink()

  return (
    <>
      {mainCategories.map((mainCategory: any) => (
        <FilterWrapper className="mb-5">
          <h2>{mainCategory.node.name}</h2>
          <div>
            {blogBink.categories
              .filter(
                (category: BlogFilterProps) =>
                  category.node.wpParent.node.databaseId ===
                  mainCategory.node.databaseId
              )
              .map((category: BlogFilterProps, index: number) => (
                <StyledBlogFilter
                  key={`${category.node.id}-${index}`}
                  id={category.node.id}
                  selected={blogBink.isSelected(category.node.id)}
                  className="d-flex align-items-center"
                >
                  <Input
                    type="checkbox"
                    checked={blogBink.isSelected(category.node.id)}
                  />
                  <span className="ms-2">{category.node.name}</span>
                </StyledBlogFilter>
              ))}
          </div>
        </FilterWrapper>
      ))}
      <button type="button" onClick={() => blogBink.reset()}>
        Wis filters
      </button>
    </>
  )
}

const Col = styled.div`
  @media (min-width: 992px) {
    height: 430px;
  }
`

const MobileBlogFilters: React.FC<BlogFiltersProps> = ({
  mainCategories,
}: any) => {
  const blogBink = useBlogBink()

  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const mobileFilterAnimations = {
    collapsed: {
      height: '0vh',
    },
    open: {
      height: '30vh',
    },
  }

  return (
    <>
      {!mobileFilterOpen && (
        <MobileFilterCollapsed onClick={() => setMobileFilterOpen(true)}>
          <div className="d-flex justify-content-end align-items-center">
            <h2 className="text-white mb-0 me-4">Filteren</h2>
            <Chevron />
          </div>
        </MobileFilterCollapsed>
      )}
      {mobileFilterOpen && (
        <MobileFilterExpanded>
          <div
            className="d-flex justify-content-between align-items-center border-bottom"
            onClick={() => setMobileFilterOpen(false)}
            onKeyDown={() => setMobileFilterOpen(false)}
            role="button"
            tabIndex={0}
          >
            <h2>Filter op categorie(ën)</h2>
            <Chevron className="me-3" style={{ height: 25, width: 25 }} />
          </div>

          <motion.div
            variants={mobileFilterAnimations}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ type: 'spring', damping: 20 }}
            tabIndex={-1}
            style={{ overflowY: 'scroll' }}
            className="d-flex flex-column me-3"
          >
            {mainCategories.map((mainCategory: any) => (
              <FilterWrapper className="my-4">
                <h2>{mainCategory.node.name}</h2>
                <div>
                  {blogBink.categories
                    .filter(
                      (category: BlogFilterProps) =>
                        category.node.wpParent.node.databaseId ===
                        mainCategory.node.databaseId
                    )
                    .map((category: BlogFilterProps) => (
                      <StyledBlogFilter
                        key={category.node.id}
                        id={category.node.id}
                        selected={blogBink.isSelected(category.node.id)}
                        className="d-flex align-items-center"
                      >
                        <Input
                          type="checkbox"
                          checked={blogBink.isSelected(category.node.id)}
                        />
                        <span className="ms-2">{category.node.name}</span>
                      </StyledBlogFilter>
                    ))}
                </div>
              </FilterWrapper>
            ))}
          </motion.div>
        </MobileFilterExpanded>
      )}
    </>
  )
}

const Topbar = styled.div<{ rotateChevron: boolean }>`
  & h2 {
    opacity: 0.65;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & span,
  & ul {
    opacity: 0.55;
  }

  & svg {
    transform: rotate(-90deg);
    ${(props) =>
      props.rotateChevron &&
      css`
        transform: rotate(90deg);
      `}
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const [reversedOrder, setReversedOrder] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const blogBink = useBlogBink()
  const [allPosts, setAllPosts] = useState<Array<any>>([])

  useEffect(() => {
    // @ts-ignore
    const newPosts = [...blogBink.posts]
    setAllPosts(reversedOrder ? newPosts.reverse() : newPosts)
  }, [reversedOrder, blogBink.isSelected])

  return (
    <div className="row">
      <Topbar
        className="d-flex justify-content-between align-items-center"
        rotateChevron={reversedOrder}
      >
        <h2>{blogBink.posts.length} resultaten</h2>
        <div className="d-flex">
          <span>Sorteren op:</span>
          <ul>
            <li className="d-flex align-items-center">
              <button
                className="me-2"
                type="button"
                onClick={() => {
                  setReversedOrder((prevState) => !prevState)
                }}
              >
                <span className="me-2">
                  {reversedOrder ? 'Oudste' : 'Nieuwste'}
                </span>
                <Chevron />
              </button>
            </li>
          </ul>
        </div>
      </Topbar>
      {blogBink.posts.length > 0 &&
        allPosts.map((post, index) => {
          const { node }: any = post

          const open: boolean = currentIndex === index

          return (
            <Col key={post.node.databaseId} className="col-lg-4 col-sm-6 mb-5">
              <motion.div
                whileHover={() => setCurrentIndex(index)}
                onTapStart={() => setCurrentIndex(index)}
                className="h-100"
              >
                <BlogGridPost node={node} fields={fields} overlay={open} />
              </motion.div>
            </Col>
          )
        })}
      {blogBink.posts.length < 1 && (
        <div>
          <h2>
            Helaas! We hebben geen projecten kunnen vinden, probeer een andere
            categorie.
          </h2>
        </div>
      )}
    </div>
  )
}

const Recap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;

  & h2 {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 992px) {
    height: 430px;
  }
`

const Front = styled(motion.div)`
  background: linear-gradient(
    0deg,
    #18263f 0%,
    rgba(24, 38, 63, 0.0203309) 97.65%,
    rgba(24, 38, 63, 0) 100%
  );
  color: ${({ theme }) => theme.color.light};
`

const Overlay = styled(motion.div)`
  position: relative;
  z-index: 99;
  background-color: rgba(210, 164, 46, 0.85);
  color: ${({ theme }) => theme.color.dark};
  align-self: stretch;
`

const Excerpt = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  fields: any
  overlay: boolean
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  node,
  overlay,
  fields,
}) => {
  const overlayAnimations = {
    collapsed: {
      height: 0,
      opacity: 0,
    },
    open: {
      height: '100%',
      opacity: 1,
    },
  }
  return (
    <div className="position-relative h-100">
      <Recap className="position-absolute w-100 h-100 d-flex flex-column justify-content-end overflow-hidden">
        {!overlay && (
          <Front>
            <div className="p-4">
              <h2>{node.title}</h2>
              <span>{node.projectrecap.caption}</span>
            </div>
          </Front>
        )}

        <AnimatePresence>
          {overlay && (
            <Overlay
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={overlayAnimations}
              transiton={{
                duration: 0.8,
                type: 'spring',
                damping: 10,
              }}
            >
              <div className="p-4 d-flex flex-column justify-content-between h-100">
                <div>
                  <h2>{node.title}</h2>
                  <span>{node.projectrecap.caption}</span>
                </div>

                <Excerpt content={node.projectrecap.excerpt} />
                <AnchorPrimary href={node.uri}>
                  {fields.readmoretext}
                </AnchorPrimary>
              </div>
            </Overlay>
          )}
        </AnimatePresence>
      </Recap>
      <Plaatjie
        image={node.projectrecap.thumbnail}
        alt=""
        className="h-100 w-100"
        style={{ maxHeight: 425 }}
      />
    </div>
  )
}

export default Projects
