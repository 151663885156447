import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

// Images
import Chevron from 'img/chevron.inline.svg'
import { motion } from 'framer-motion'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Vacatures: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpVacature,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacatureQueryQuery>(graphql`
    query vacatureQuery {
      allWpVacature(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacatureFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacature.edges

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-lg-4 mt-5">
                <BlogGridPost node={node} fields={fields} />
              </div>
            )
          })}
        {blogBink.posts.length < 1 && (
          <div className="col-lg-6 mt-4">
            <h2>Helaas!</h2>
            <p>
              We hebben geen berichten met deze categorie kunnen vinden. Probeer
              een andere categorie of probeer het later nog eens.
            </p>
          </div>
        )}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

const Recap = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    color: ${({ theme }) => theme.color.dark};
    font-size: 22px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  & p {
    color: ${({ theme }) => theme.color.dark};
  }

  & svg {
    width: 14.5px;
    height: 22.5px;
  }
`

const Image = styled(Plaatjie)`
  height: 280px;
`

const Excerpt = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    @media (max-width: 575px) {
      -webkit-line-clamp: 2;
    }
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpVacature
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Recap className="h-100">
      <NavLink to={node.uri || '/'} className="position-relative h-100">
        <motion.div
          className="h-100"
          onHoverStart={() => setHovered(true)}
          onHoverEnd={() => setHovered(false)}
        >
          <div className="h-50">
            <Image
              image={node.vacatureRecap?.thumbnail}
              className="w-100"
              alt=""
            />
          </div>
          <div className="d-flex flex-column align-items-between justify-content-between p-4 h-50">
            <h2 className="mb-3">{node.title}</h2>
            <Excerpt content={node.vacatureRecap?.excerpt} className="mb-5" />
            <div className="d-flex justify-content-end mt-auto">
              <motion.div animate={hovered ? { x: 10 } : { x: 0 }}>
                <Chevron />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </NavLink>
    </Recap>
  )
}

export default Vacatures
